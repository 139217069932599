import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import callApi from "../utils/callApi"
import { parse } from "query-string"
import Layout from "../components/layouts/Layout/layout"
import Section from "../components/blocks/Section/Section"
import FormationLocation from "../components/blocks/FormationLocation/FormationLocation"
import { Alert, Dialog, DialogTitle } from "@mui/material"
import FormationFormulaire from "../components/sections/RechercheFormation/FormationFormulaire"
import LinkButtonBlock from "../components/blocks/ButtonBlock/LinkButtonBlock"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"

const FormationTemplate = ({ pageContext }) => {
  const page = pageContext.page.attributes
  const components = page.contenu.map(i => componentFactory(i, pageContext))

  const location = useLocation()

  const searchParams = parse(location.search)
  const evenId = searchParams.id
  const [formation, setFormation] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [modalFormation, setModalFormation] = useState()
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!evenId) {
          setLoaded(true)
          return
        }

        const data = await callApi("get", `/api/formationdetails/?id=${evenId}`)
        if (data.data.success) {
          setFormation(data.data.formation)
          setLoaded(true)
        } else {
          setError(true)
        }
      } catch (err) {
        setError(true)
        setLoaded(false)
      }
    }

    fetchData()
  }, [evenId])

  if (formation && formation.id) {
    var ville = formation.etablissement.ville || ""
    var dep = formation.etablissement.code ? " (" +formation.etablissement.code + ")" : ""
    var localisation = !formation.typeFormation?.data?.attributes.isRemote
      ? ville + dep
      : formation.typeFormation?.data?.attributes.shortText
    var date_debut = new Date(formation.date_debut)
    var formatedDateDebut = date_debut.toLocaleDateString("fr-FR", {
      weekday: "long",
      //year: "numeric",
      month: "long",
      day: "numeric",
    })
    var date_fin = new Date(formation.date_fin)
    var formatedDateFin = date_fin.toLocaleDateString("fr-FR", {
      weekday: "long",
      //year: "numeric",
      month: "long",
      day: "numeric",
    })
    var dateFinInscription = new Date(date_debut)
            dateFinInscription.setDate(
              date_debut.getDate() // - formation?.formation_type_can_register_until 
            )
            const now = new Date()
            now.setHours(0, 0, 0,0)
            var isPast = now.getTime() >= dateFinInscription
  }

  const showInscriptionFormulaire = formationId => {
    if (formation.id === formationId) {
      setModalFormation(formation)
    }
    setOpen(true)
  }
  const chemin = [...page.chemin]
  if (formation && formation.id) {
    chemin.push({
      titre: formation.evenement_type.titre,
    })
  }

  return (
    <>
      <Seo
        description={page.metaDescription}
        title={page.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={chemin}>
        <Section className="Next-formation">
          {!loaded && !error && <h2>Chargement de la formation en cours...</h2>}

          {error && (
            <Alert severity="warning">
              Erreur lors de la récupération de la formation.
            </Alert>
          )}

          {formation && formation.id && (
            <>
              <div className="formations container">
                <div className="formation-detail-head">
                  <div className="formation-detail">
                    <div>
                      <strong>
                        {formatedDateDebut === formatedDateFin ? (
                          <>Le {formatedDateDebut}</>
                        ) : (
                          <>
                            Du&nbsp;
                            {formatedDateDebut}&nbsp;
                            {/* <br /> */}
                            au&nbsp;
                            {formatedDateFin}
                          </>
                        )}
                      </strong>
                    </div>
                    <div>{localisation}</div>
                    {formation.evenement_type.titre === "Autre" ? (
                      <>
                        <div className="titre">
                          {formation.theme_autre_titre}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="titre">
                          {formation.evenement_type.titre}
                        </div>
                      </>
                    )}
                    {isPast ? (
                      <div>
                        <div className="button pass">Inscription Terminée</div>
                      </div>
                    ) : formation.is_registred ? (
                      <div>
                        <div className="button inscrit">Inscrit</div>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="button inscription"
                          onClick={e => {
                            showInscriptionFormulaire(formation.id)
                          }}
                        >
                          Je m'inscris
                        </button>
                      </div>
                    )}
                  </div>
                  <div className={"info"}>
                    <div>
                      <FormationLocation item={formation} />

                      <h4 style={{ marginTop: 30 }}>Horaires</h4>
                      <ul>
                        {formation.creneaux.map((c, i) => (
                          <li key={i}>
                            {`Le ${c.date_fr} de ${c.heure_debut} à ${c.heure_fin}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <br />
                    <p>{formation.commentaires_publics} </p>

                    {formation.evenement_type.id &&
                      formation.evenement_type.titre !== "Autre" && (
                        <LinkButtonBlock
                          text={"En savoir plus sur cette formation"}
                          url={"/formations/" + formation.evenement_type.slug}
                        />
                      )}
                  </div>
                </div>
              </div>
              <Dialog onClose={handleClose} open={open}>
                <DialogTitle align="center">
                  {formation && formation.evenement_type.titre}
                </DialogTitle>
                <FormationFormulaire
                  close={handleClose}
                  formation={modalFormation}
                />
              </Dialog>
            </>
          )}

          {components}
        </Section>
      </Layout>
    </>
  )
}

export default FormationTemplate
